import React, {useState} from 'react';
import {Rating} from '@smastrom/react-rating';
import {useForm, Controller} from 'react-hook-form';
import '@smastrom/react-rating/style.css';
import Modal from 'react-bootstrap/Modal';
import arrow from '../../images/arrow2.svg';
import arrowLeft from '../../images/arrowLeftWhite.svg';

const Review = (props) => {
    const {
        register,
        handleSubmit,
        control,
        formState: {errors},
    } = useForm({
        mode: 'onBlur',
        defaultValues: {
            rating: 0,
            message: '',
        },
    });

    const [thankYouMessage, setThankYouMessage] = useState(false);

    function onSubmit(data) {
        setThankYouMessage(true);
        // alert(JSON.stringify(data, undefined, 2));
    }


    const {setReviewModal, reviewModal} = props;

    const hideReviewModal = () => {
        setReviewModal(false);
    };

    return (<>
                <Modal size="sm"
                       fullscreen={true}
                       id={'review-modal'}
                       scrollable={false}
                       dialogClassName={'d-flex align-items-center m-0'}
                       aria-labelledby="contained-modal-title-vcenter"
                       centered
                       backdrop={'static'}
                       className={'p-3 m-0 info-modal'}
                       show={reviewModal} onHide={hideReviewModal}
                >
                    <Modal.Body className={'p-3'}>

                        {!thankYouMessage ? <div className="review-form">
                            <h3 className="mt-3 mb-4" style={{fontSize: '22px'}}>Leave us some feedback!<br/>We’d love to
                                hear from you.</h3>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <label htmlFor="message" className="w-100">
                                        <textarea className="w-100 p-3" placeholder="Type here…" type="textarea"
                                                  id="message" {...register('message', {required: false})}/>
                                </label>

                                <div className="my-4"><Controller
                                        control={control}
                                        name="rating"
                                        rules={{
                                            validate: (rating) => rating > 0,
                                        }}
                                        render={({field: {onChange, onBlur, value}}) => (
                                                <Rating
                                                        value={value}
                                                        isRequired
                                                        onChange={onChange}
                                                        visibleLabelId="rating_label"
                                                        onBlur={onBlur}
                                                />
                                        )}
                                />
                                {errors.rating && <span className="d-block">Rating is required.</span>}
                                </div>

                                <button type="submit" className="button d-inline-flex align-items-center justify-content-center gap-3"><span>Submit</span><img style={{width: 40, height: 'auto'}}
                                                                             src={arrow}
                                                                             alt="arrowLeft"/></button>
                            </form>
                        </div> : <div>Thank You!<br/><br/> <a
                                className="button d-inline-flex align-items-center justify-content-center gap-3"
                               href="/locations/5"><img src={arrowLeft} alt="arrowLeft"/><span>Back to home</span></a></div>}


                    </Modal.Body>
                </Modal>
            </>
    )
            ;
};

export default Review;